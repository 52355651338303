export const About = (props) => {
  return (
    <div id="about" name="about">
      <div className="container">
        <div className="row">
          <div>
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="container">
            <div className="about-text">
              <h2>Om meg</h2>
              <h4>Monica Lexau</h4>
              <p>
                Etter å ha drevet en klinikk i sentrum av Oslo i mange år, ble
                virksomheten flyttet til Tangen på Nesodden i 2000.
                Aromaklinikken ble da til. Der har Monica utført det meste innen
                hudpleie, hårfjerning og massasje i over 20 år. Målet er å holde
                fokus på å levere profesjonelle behandlinger av topp kvalitet i
                rolige og behagelige omgivelser. Aromaklinikken er stedet for
                velvære og avstressing.
              </p>
              <br></br>
              <br></br>
              <h3>Velkommen!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
