import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navigation = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const closeNavOnClick = () => {
    setIsMobileMenuOpen(false)
  };

  const toggleNav = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            onClick={toggleNav}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link
            to="page-top"
            className="navbar-brand page-scroll"
            smooth={true}
            duration={500}
          >
            Aromaklinikken
          </Link>
        </div>

        <div
          className={`collapse navbar-collapse ${isMobileMenuOpen ? 'in' : ''}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                to="services"
                smooth={true}
                duration={500}
                onClick={closeNavOnClick}
              >
                Behandlinger
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                smooth={true}
                duration={500}
                onClick={closeNavOnClick}
              >
                Prisliste
              </Link>
            </li>
            <li>
              <Link
                to="portfolio"
                smooth={true}
                duration={500}
                onClick={closeNavOnClick}
              >
                Gavekort
              </Link>
            </li>
            <li>
              <Link
                to="info"
                smooth={true}
                duration={500}
                onClick={closeNavOnClick}
              >
                Info
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                duration={500}
                onClick={closeNavOnClick}
              >
                OM MEG
              </Link>
            </li>
            <li>
              <a
                href="https://www.eadministration.dk/kunde/kundelogin.asp?klient=1664"
                onClick={closeNavOnClick}
              >
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
