export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Prisliste</h2>
        </div>
        <br></br>
        <br></br>
        <h1 className="pris">Kroppsbehandling</h1>
        <div className="row">
          {kroppsbehandling
            ? kroppsbehandling.map((d, i) => (
                <div key={`${d.behandling}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <div className="testimonial-meta"> {d.behandling} </div>
                      <p>{d.pris}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <br></br>
        <h1 className="pris">Ansiktsbehandling</h1>
        <div className="row">
          {Ansiktsbehandling
            ? Ansiktsbehandling.map((d, i) => (
                <div key={`${d.behandling}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <div className="testimonial-meta"> {d.behandling} </div>
                      <p>{d.pris}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <br></br>
        <h1 className="pris">Vipper og Bryn</h1>
        <div className="row">
          {vipperOgBryn
            ? vipperOgBryn.map((d, i) => (
                <div key={`${d.behandling}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <div className="testimonial-meta"> {d.behandling} </div>
                      <p>{d.pris}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <br></br>
        <h1 className="pris">Hårfjerning</h1>
        <div className="row">
          {hårfjerning
            ? hårfjerning.map((d, i) => (
                <div key={`${d.behandling}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      <div className="testimonial-meta"> {d.behandling} </div>
                      <p>{d.pris}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

var kroppsbehandling = [
  {
    behandling: "Aromaterapi: 1 time",
    pris: "1200kr",
  },
  {
    behandling: "Rygg/nakke/skuldermasasje 1/2 time",
    pris: "800kr",
  },
  {
    behandling:
      "Rygg/nakke/skuldermassasje 3/4 time (De siste 10 min: hode og ansiktsmassasje)",
    pris: "1000kr",
  },
  {
    behandling: "Fotpleie",
    pris: "1000kr (300,- ekstra med lakk)",
  },
];
var Ansiktsbehandling = [
  {
    behandling: "Dyprens (uten massasje)",
    pris: "960kr",
  },
  {
    behandling: "Dyprens med massasje",
    pris: "1200kr",
  },
  {
    behandling: "Dyprens med ansikt og ryggmassasje",
    pris: "1600kr",
  },
  {
    behandling:
      "Ansiktsbehandling, Decléor med spesialmaske. Rynke, fukt, uren/fet, sensitive",
    pris: "1400kr",
  },
  {
    behandling: "Fruktsyrebehandling (MD. Formulations)",
    pris: "700kr",
  },
  {
    behandling: "Minilift",
    pris: "700kr",
  },
];
var vipperOgBryn = [
  {
    behandling: "Farging av bryn",
    pris: "320kr",
  },
  {
    behandling: "Farging av vipper",
    pris: "420kr",
  },
  {
    behandling: "Voks/form av bryn",
    pris: "350kr",
  },
];
var hårfjerning = [
  {
    behandling: "Voks legger",
    pris: "450kr",
  },
  {
    behandling: "Voks lår",
    pris: "450kr",
  },
  {
    behandling: "Voks bikini",
    pris: "450kr",
  },
  {
    behandling: "Voks av legger/bikini/lår",
    pris: "900kr",
  },
  {
    behandling: "Voks brasiliansk",
    pris: "660kr",
  },
  {
    behandling: "Voks overleppe",
    pris: "300kr",
  },
  {
    behandling: "Voks ansikt (inkl bryn)",
    pris: "550kr",
  },
  {
    behandling: "Voks armer",
    pris: "450kr",
  },
  {
    behandling: "Voks under armer",
    pris: "400kr",
  },
  {
    behandling: "Voks rygg",
    pris: "650kr",
  },
  {
    behandling: "Voks/form av bryn",
    pris: "350kr",
  },
];
