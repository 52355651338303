export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title"></div>
        <div className="row">
          <div className="col">
            {behandlinger
              ? behandlinger.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="">
                    {" "}
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <div className="col">
            {behandlingerTo
              ? behandlingerTo.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="">
                    {" "}
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};

var behandlinger = [
  {
    icon: "fa fa-wordpress",
    name: "HUDPLEIE",
    text: "Huden blir renset i dybden og man får peeling, damp og klemming, etterfulgt av massasje og maske.  Behandlingen tar ca 90 min og passer til alle! Den kan også fås uten massasje, da tar behandlingen ca 60 min. ",
  },
  {
    icon: "fa fa-cart-arrow-down",
    name: "HUDPLEIE MED RYGG, NAKKE OG SKULDERMASSASJE",
    text: "Her starter vi behandlingen med en 20 minutters massasje av ryggen mens du ligger på magen.  Så følger en ansiktsbehandling som beskrevet under HUDPLEIE.   Behandlingen tar nesten to timer og kan virkelig anbefales!  Perfekt som gave! ",
  },
  {
    icon: "fa fa-language",
    name: "MINILIFT / HYDRADERMIE LIFT",
    text: "Er en oppstrammende behandling som i tillegg til å behandle og styrke huden, strammer opp trekkene i dybden ved hjelp av muskelgymnastikk.  Styrken og fastheten i dybden av musklene gjenopprettes, celleaktiviteten reaktiveres og trekkene glattes ut på en helt oppsiktsvekkende måte.  Hydradermie Lift virker som styrketrening i helsestudioet.  Den glatter ut trekkene ved å påvirke musklene og gir ansiktet et friskt og sunt utseende. Gir ny glød til trett og slapp hud og øker blodsirkulasjonen. Strammer opp ansiktsmusklene så ansiktet får et løft! Behandlingen tar ca 30 min og fungerer best som er kur på 8-10 ganger. ",
  },
  {
    icon: "fa fa-plane",
    name: "PERMANENT HÅRREDUKSJON",
    text: "En rask og mindre smertefull metode for effektiv hårfjerning med langvarig virkning.  Forskning viser at effektene er like gode som ved bruk av laser, samtidig som metoden er tryggere og rimeligere! LHE = Light Heat Energy er utviklet av ledende vitenskapsmenn innen laser-og lysteknologi.  På grunn av de gode resultatene er teknologien allerede blitt ledende på verdensmarkedet innen permanent hårreduksjon.  LHE- metoden er patentert og fås kun ved bruk av apparater fra Radiancy. Best resultat oppnås på pigmenterte mørke hår.  For å få effekt må håret være i kontakt med hårroten,noe som bare skjer i en viss periode av hårets vekstfase.  Siden hårene er i ulike vekstfaser kreves ca.  5 behandlinger (gjelder også laser). ",
  },
];
var behandlingerTo = [
  {
    icon: "fa fa-pie-chart",
    name: "AROMATERAPI",
    text: "Avslappende massasje av hele kroppen med vellduftende eteriske oljer tilpasset den enkeltes behov.  Gir en total følelse av ro og velvære.  Aromaterapi styrker kroppens evne til selvbeherskelse og gir økt energi og livsglede.  De eteriske oljene virker i dybden. Behandlingen tar ca 1 time og er også perfekt som gave!",
  },
  {
    icon: "fa fa-pie-chart",
    name: "FOTPLEIE",
    text: "Stell av friske føtter. Fjerning av hard hud og stell av negler og neglebånd. Her avsluttes behandlingen med en fotmassasje. Lakk mot et tillegg i prisen.",
  },
  {
    icon: "fa fa-cloud-download",
    name: "HUDPLEIE MED SPESIALMASKE",
    text: "En utvidet hudpleie med spesialmaske fra Decléor tilpasset den enkeltes hudtype. Etterfulgt av massasje av ansikt, nakke og skuldre.  Ellers likt som i vanlig hudpleie.  Behandlingen tar ca 1.  45 min. ",
  },
  {
    icon: "fa fa-pie-chart",
    name: "KROPPSMASSASJE",
    text: "En klassisk massasje for deg med muskelspenninger eller som trener mye og belaster musklene. Øker blodsirkulasjonen. Behandlingen tar 60 min. ",
  },
  {
    icon: "fa fa-pie-chart",
    name: "RYGG/NAKKE OG SKULDERMASSASJE",
    text: "Godt for den som har høye skuldre og stramme muskler rundt nakke og rygg. Løsner opp spenninger i musklaturen, øker blodsirkulasjonen og hjelper mot stresshodepine! Her kan man velge mellom 30 og 45* min. (*Her brukes de siste 10 min.  til hode og ansiktsmassasje). ",
  },
  {
    icon: "fa fa-pie-chart",
    name: "Hårfjerning med voks",
    text: "Jeg har mange års erfaring innen voksing, og med riktig teknikk og god hygiene får du en trygg og god behandling. Jeg bruker stripsfri Hot Wax fra Italwax i de sensitive områdene som bikini/brasiliansk og ansikt. Denne voksen regnes som det beste på markedet og inneholder mange pleiende ingredienser. Det påføres olje på område før behandling, dette virker beskyttende, og er derfor meget skånsom mot huden og oppleves av de fleste som mindre smertefull enn vanlig stripsvoks. Hårene holder seg borte i 3-6 uker.",
  },
];
