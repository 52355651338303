import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gavekort</h2>
          <p>
            Gi bort et gavekort til en du er glad i! <br></br><br></br>
            Gavekortet får du tak i ved å komme innom klinikken og kjøpe det, 
            eller du kan ringe eller sende sms til 99700430, så ordner vi det med Vipps og at du lager det selv. 
            Jeg vil da sende gavekortnummeret så du kan skrive dette på kortet.
            <br></br><br></br> Du kan velge blant behandlingene i vår meny, eller velge
            et beløp så hun eller han kan velge selv. <br></br> En perfekt gave
            til jul, bursdag, mors/farsdag eller til noen du synes fortjener
            det! <br></br>
            <br></br> Velkommen til nytelse og velvære. Du fortjener det beste!
          </p>
          <br></br>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-lg-4">
                    <Image title={d.title} smallImage={d.smallImage} />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
