export const Info = (props) => {
  return (
    <div id="info">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/info.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <br></br>
              <h2>Informasjon</h2>
              <br></br>
              <h3>Kontakt</h3>
              <br></br>
              <p>99 70 04 30</p>
              <p>
                <a href="https://www.google.no/maps/place/Skogveien+2,+1450+Nesoddtangen/@59.8552213,10.6563282,17z/data=!3m1!4b1!4m5!3m4!1s0x46416b94a75f5a75:0x4d4a3766b502c1db!8m2!3d59.8552213!4d10.6585169?hl=no">
                  SKOGVEIEN 2
                </a>
              </p>
              <a
                id="sos"
                href="https://www.facebook.com/aromaklinikken.spasenter"
              >
                Aromaklinikken på Facebook
              </a>
              <br></br>
              <br></br>
              <h3>Åpningstider</h3>
              <br></br>
              <p>
                Mandag - fredag 10:00 - 16:30. Ettermiddag/kveld etter avtale.
              </p>
              <br></br>
              <br></br>
              <p id="fleks">
                Jeg ønsker å være fleksibel, ta kontakt hvis <br></br> du vil ha
                time utenfor vanlige åpningstider.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
